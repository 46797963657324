import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { COGNITO_BEARER } from '../interceptors/authentication.interceptor';
import { Aircraft } from '../models/aircraft.model';

@Injectable({
  providedIn: 'root'
})
export class AircraftService {
  // selectedAircraft?: Aircraft;

  constructor(private http: HttpClient) {
    console.log("AircraftService constructor");
    // this.selectAircraft();
   }

  url = `${environment.apiUrl}/api/v1/aircraft/`;

  getAllAircraft(): Observable<Aircraft[]> {
    return this.http.get<Aircraft[]>(this.url, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }

  updateAircraft(aircraft: Aircraft): Observable<Aircraft> {
    return this.http.put<Aircraft>(this.url+aircraft.id, aircraft, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }

  createAircraft(aircraft: Partial<Aircraft>): Observable<Aircraft> {
    return this.http.post<Aircraft>(this.url, aircraft, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }

  // clearSelectedAircraft() {
  //   this.selectedAircraft = undefined
  // }

  // selectAircraft(aircraft?: Aircraft) {
  //   if (aircraft) {
  //     this.selectedAircraft = aircraft;
  //     return;
  //   }
  //   // get list of all aircraft for this user (all if admin), and just pick the first one
  //   // In the future it could get this from localsorage or something similar to persist
  //   this.getAllAircraft().subscribe(aircraft => {
  //     this.selectedAircraft = aircraft[0];
  //   });
  // }
}
