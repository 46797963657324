

<div class="container py-1">&nbsp;</div>

<div class="container">
    <h2 class="col-md-8 offset-md-2">Beta Signup</h2>
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <p>Thanks for your interest in the LSRM.ai!  We're currently collecting interest for an early beta, as well as formal launch.  
                Please provide the requested info in the form below, including details about the aircraft you maintain and operate.   
                Provide information about the aircraft and engine manufacturer and types.  In addition please provide some
                notes on installed avionics and other equipment.  This will help us tailor and test the system, and help determine 
                priorities for the beta as will as further releases. If you maintain multiple aircraft, list them all.</p>
        </div>
    </div>

<form [formGroup]="betaSignupForm" (ngSubmit)="onSubmit()">
    <div class="row py-1">
        <div class="form-group col-md-8 offset-md-2">
            <label for="inputName">Full Name</label>
            <input [class.is-invalid] = "name.invalid && name.touched" [class.is-valid] = "name.valid && name.touched" 
                type="text" id="name" formControlName="name" class="form-control" placeholder="Full Name" required>
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <small *ngIf="name.errors?.['required']" class="text-danger">Name is required.</small>
            </div>
        </div>
    </div>

    <div class="row py-1">
        <div class="form-group col-md-8 offset-md-2">
            <label for="inputEmail">Email Address</label>
            <input [class.is-invalid] = "email.invalid && email.touched" [class.is-valid] = "email.valid && email.touched"
                type="email" id="email" formControlName="email" class="form-control" placeholder="lsrm@google.com" required>
            <div *ngIf="email.invalid && (email.dirty || email.touched)">
                <small *ngIf="email.errors?.['required']" class="text-danger">Email is required.</small>
                <small *ngIf="email.errors?.['email']" class="text-danger">Please provide a valid email address.</small>
            </div>
            <small id="emailHelp" class="form-text text-muted">We'll never share your email.</small>
        </div>
    </div>

    <div class="row py-1">
        <div class="form-group col-md-8 offset-md-2">
            <label for="inputNotes">Notes</label>
            <textarea  style="height: 150px" [class.is-invalid] = "notes.invalid && notes.touched" [class.is-valid] = "notes.valid && notes.touched" 
                id="notes" formControlName="notes" class="form-control" required
                placeholder="Aircraft details - manufacturer, type, engine, avionics, etc."></textarea>
            <div *ngIf="notes.invalid && (notes.dirty || notes.touched)">
                <small *ngIf="notes.errors?.['required']" class="text-danger">Please provide some notes and details on the aircraft</small>
            </div>
        </div>
    </div>

    <div *ngIf="serverError || serverSuccess" class="row py-1">
        <div class="form-group col-md-8 offset-md-2">
            <div class="alert" [class.alert-danger] = "serverError" [class.alert-success] = "serverSuccess" role="alert">
                {{serverError}} {{serverSuccess}}
            </div>
        </div>
    </div>

    <div class="row  py-1">
        <div class="form-group col-md-8 offset-md-2">
            <button type="submit"  [disabled]="!betaSignupForm.valid" class="btn btn-primary justify-content-md-center col-md-3">Submit</button>
        </div>
    </div>
</form>


</div>