
import { ResourcesConfig } from '@aws-amplify/core';
import { environment } from './environments/environment';

// import {CognitoUserPoolConfig } from '@aws-amplify/auth';

export const awsconfig: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolClientId: environment.cognito.userPoolClientId,
            userPoolId: environment.cognito.userPoolId,
            loginWith: { // Optional
                // oauth: {
                //     domain: 'abcdefghij1234567890-29051e27.auth.us-east-1.amazoncognito.com',
                //     scopes: ['openid','email','phone','profile','aws.cognito.signin.user.admin'],
                //     redirectSignIn: ['http://localhost:3000/','https://example.com/'],
                //     redirectSignOut: ['http://localhost:3000/','https://example.com/'],
                //     responseType: 'token',
                // },
                username: true,
                email: false, // Optional
                phone: false, // Optional
                
            },
            passwordFormat: {
                minLength: 8,
                requireLowercase: true,
                requireUppercase: true,
                requireNumbers: true,
                requireSpecialCharacters: true,
            }
        }
    }
};