import {
  HttpContextToken,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest
} from '@angular/common/http';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export const COGNITO_BEARER = new HttpContextToken(() => false);

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  if (req.context.get(COGNITO_BEARER)) {
    return from(fetchAuthSession({ forceRefresh: false })).pipe(
      switchMap((authSession) => {
        let authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${authSession.tokens?.idToken}`
          }
        });
        console.log("Bearer token set: " + authSession.tokens?.idToken);
        return next(authReq);
      })
    );
  }
  return next(req);
}
