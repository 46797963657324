import { NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbTypeahead, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Aircraft } from '../../models/aircraft.model';
import { AircraftService } from '../../services/aircraft.service';
import { AuthService } from '../../services/auth.service';
import { PicklistService } from '../../services/picklist.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'component-aircraft-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgbTypeaheadModule,
  ],
  templateUrl: './aircraft-edit.component.html',
  styleUrl: './aircraft-edit.component.scss'
})
export class AircraftEditComponent implements OnChanges{
  @Input() aircraft: Aircraft;
  @ViewChild('content') private modalContent: TemplateRef<AircraftEditComponent>
  @ViewChild('closeModal') closeModal: ElementRef
  @ViewChild('aircraftInput') aircraftInput: NgbTypeahead;
  @ViewChild('engineInput') engineInput: NgbTypeahead;
  @Output() refreshListEvent = new EventEmitter();
  serverError: string = '';
  serverSuccess: string = '';
  aircraft_vendors: string[] = [];
  engine_vendors: string[] = [];

  constructor(public auth: AuthService,
              private formBuilder: FormBuilder, 
              private aircraftService: AircraftService, 
              private modalService: NgbModal,
              private userService: UserService,
              private router: Router,
              private picklistService: PicklistService) { 
  }

  ngOnInit() {
    this.picklistService.getAircraftVendors().subscribe({
      next: (response) => this.aircraft_vendors = response,
      error: (error) => console.error(error),
    });
    this.picklistService.getEngineVendors().subscribe({
      next: (response) => this.engine_vendors = response,
      error: (error) => console.error(error),
    });
  } 


  aircraftForm =  this.formBuilder.group({
    id: [''],
    username: '',
    registration: ['', [Validators.required]],
    airframe_vendor: ['', [Validators.required]],
    airframe_model: ['', [Validators.required]],
    airframe_serial: '',
    engine_vendor: ['', [Validators.required]],
    engine_model: ['', [Validators.required]],
    engine_serial: '',
  });

  get username() {
    return this.aircraftForm.get('username') as FormControl;
  }

  get registration() {
    return this.aircraftForm.get('registration') as FormControl;
  }

  get airframe_vendor() {
    return this.aircraftForm.get('airframe_vendor') as FormControl;
  }

  get airframe_model() {
    return this.aircraftForm.get('airframe_model') as FormControl;
  }

  get airframe_serial() {
    return this.aircraftForm.get('airframe_serial') as FormControl;
  }

  get engine_vendor() {
    return this.aircraftForm.get('engine_vendor') as FormControl;
  }

  get engine_model() {
    return this.aircraftForm.get('engine_model') as FormControl;
  }

  get engine_serial() {
    return this.aircraftForm.get('engine_serial') as FormControl;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reset()
  }

  reset() {
    this.aircraftForm.reset()
    if (this.aircraft) {
      this.aircraftForm.patchValue({
        id: String(this.aircraft.id),
        username:  this.aircraft.username,
        registration: this.aircraft.registration,
        airframe_vendor: this.aircraft.airframe_vendor,
        airframe_model: this.aircraft.airframe_model,
        airframe_serial: this.aircraft.airframe_serial,
        engine_vendor: this.aircraft.engine_vendor,
        engine_model: this.aircraft.engine_model,
        engine_serial: this.aircraft.engine_serial,
      })
    }
    this.serverError = '';
    this.serverSuccess = '';
  }

 
  openEditModal() {
		this.modalService.open(this.modalContent, {});
	}


  aircraftFocus$ = new Subject<string>();
	aircraftClick$ = new Subject<string>();

  searchAircraft: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.aircraftClick$.pipe(filter(() => !this.aircraftInput.isPopupOpen()));
		const inputFocus$ = this.aircraftFocus$;

		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			map((term) =>
				(term === '' ? this.aircraft_vendors : this.aircraft_vendors.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10),
			),
		);
	};


  engineFocus$ = new Subject<string>();
	engineClick$ = new Subject<string>();

  searchEngine: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    console.log("searchAircraft: aircraftInput: ", this.aircraftInput)
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.engineClick$.pipe(filter(() => !this.engineInput.isPopupOpen()));
		const inputFocus$ = this.engineFocus$;

		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			map((term) =>
				(term === '' ? this.engine_vendors : this.engine_vendors.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10),
			),
		);
	};


  onSubmit(): void {

    if (this.aircraftForm.valid) {
      if (this.aircraft?.id) {
        Object.assign(this.aircraft, this.aircraftForm.value);
        console.log("Going to patch existing aircraft")
        this.aircraftService.updateAircraft(this.aircraft).subscribe({
          next: data => {
            this.serverSuccess = "Successfully updated aircraft info.";
            // this.aircraftService.selectAircraft(data)
            this.reset()
            this.closeModal.nativeElement.click()
          },
          error: error => {
            this.serverSuccess = '';
            this.serverError = error.error.detail;
          }
        })
      } else {
        console.log("Going to add an aircraft")
        const newAircraft: Partial<Aircraft> = {}
        Object.assign(newAircraft, this.aircraftForm.value);
        console.log("newAircraft: ", newAircraft)
        this.aircraftService.createAircraft(newAircraft).subscribe({
          next: aircraft => {
            this.serverSuccess = "Successfully added aircraft.";
            this.refreshListEvent.emit()
            this.reset()
            this.closeModal.nativeElement.click()
            if(this.userService.user!.selected_aircraft == null) {
              this.userService.selectAircraft(aircraft)
              this.router.navigate(['/docs']);
            }
          },
          error: error => {
            this.serverSuccess = '';
            this.serverError = error.error.detail;
          }
        })
      }
    }
  }
}
