import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideHttpCache, withHttpCacheInterceptor } from '@ngneat/cashew';
import { routes } from './app.routes';
import { authInterceptor } from './interceptors/authentication.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([authInterceptor, withHttpCacheInterceptor()])),
    provideHttpCache()
  ]
};
