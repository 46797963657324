import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { COGNITO_BEARER } from '../interceptors/authentication.interceptor';
import { Aircraft } from '../models/aircraft.model';
import { Document } from '../models/document.model';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  base_url = `${environment.apiUrl}/api/v1/`;

  getAllDocuments(): Observable<Document[]> {
    const url = this.base_url + 'docs/';
    return this.http.get<Document[]>(url, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }

  getAircraftDocuments(aircraft: Aircraft): Observable<Document[]> {
    const url = this.base_url + `aircraft/${aircraft.id}/document/`;
    return this.http.get<Document[]>(url, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }

  initialUpload(document: Document, aircraft: Aircraft): Observable<Document> {
    console.log("Adding document for aircraft: ", aircraft);
    const url = this.base_url + `aircraft/${aircraft.id}/document/`;
    return this.http.post<Document>(url, document, { context:  new HttpContext().set(COGNITO_BEARER, true)})
  }

  uploadFileToS3(s3Url:string, formData: FormData): Observable<any> {
    return this.http.post(s3Url, formData)
  }

  processUploadedFile(document: Document, aircraft: Aircraft): Observable<Document> {
    return this.http.post<Document>(this.base_url + `aircraft/${aircraft.id}/document/process/${document.id}`,
       null, { context:  new HttpContext().set(COGNITO_BEARER, true)})
  }

  updateAircraftDocument(document: Document, aircraft: Aircraft): Observable<Document> {
    return this.http.patch<Document>(this.base_url + `aircraft/${aircraft.id}/document/`,
       document, { context:  new HttpContext().set(COGNITO_BEARER, true)})
  }
}
