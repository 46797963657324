
<main>
  <section class="lsrm-bg-img">
    <div class="container py-1">&nbsp;</div>

    <div class="container">
      <h2>Light Sport Maintenance Assistant</h2>
      <div class="row">
          <div class="col">
            <p class="lead">Empowering Light Sport aircraft maintainers (LSRMs, LSRIs and A&Ps) and owners to swiftly access crucial information.</p>
            <ul>
            <li class="mb-0">Ask any maintenance question and receive not only a clear, detailed response tailored to the specific aircraft but also the specific document and page references used for the response.</li>
            <li class="mb-0">Effortlessly manage aircraft configuration and its related systems documentation.</li>
            </ul>
          </div>
      </div>

      <div class="row py-1">
        <div class="form-group">
            <button type="button" id="sendlogin" class="btn btn-primary mx-2" [routerLink]="['/login']" [queryParams]="{initialState: 'signUp'}">Signup</button>
            <button type="button" id="home.pricing" class="btn btn-primary mx-2" [routerLink]="['/pricing']">Pricing</button>
        </div>
    </div>

    
    

    </div>

    <div class="container py-4">
      <iframe width="360" height="200" src="https://www.loom.com/embed/d2208c92a984425b803ead8008b3b79d?sid=6860ff36-756c-4f12-8744-257304ee8b73" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>


  </section>
</main>


