import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { COGNITO_BEARER } from '../interceptors/authentication.interceptor';
import { Aircraft } from '../models/aircraft.model';
import { Search } from '../models/search.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) {}


  base_url = `${environment.apiUrl}/api/v1/`;

  public answerQuestion(aircraft: Aircraft, search: Search): Observable<Search> {
    const url = this.base_url + `aircraft/${aircraft.id}/search/`;
    return this.http.post<Search>(url, search, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }
}
