

<div class="container py-1">&nbsp;</div>

<div class="container">
    <h2>Users</h2>
    <div class="row">
        <div class="col">
			<table class="table table-striped">
				<thead>
					<tr>
						<th scope="col">ID</th>
						<th scope="col">Username</th>
						<th scope="col">Email</th>
						<th scope="col">Cognito Sub</th>
						<th scope="col">Stripe Customer ID</th>
						<th scope="col">Selected Aircraft</th>
						<th scope="col">Subscription</th>
					</tr>
				</thead>
				<tbody>
					@for (user of users; track user.id) {
						<tr>
							<th scope="row">{{ user.id | number }}</th>
							<td>{{ user.username }}</td>
							<td>{{ user.email }}</td>
                            <td>{{ user.cognito_sub }}</td>
                            <td>{{ user.stripe_customer_id }}</td>
                            <td>{{ user.selected_aircraft?.registration }}</td>
							<td>{{ user.stripe_product?.name }}</td>
						</tr>
						}
                </tbody>
			</table>
		</div>
	</div>

</div>
