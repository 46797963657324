<ng-template #content let-modal>
    <form [formGroup]="aircraftForm" (ngSubmit)="onSubmit()">
        <div class="modal-header">
            <h4 class="modal-title">Add / Edit Aircraft</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Cross click')"></button>
        </div>
        <div class="container">
       
            <div class="modal-body">

                <div class="row py-1" *ngIf="auth.admin">
                    <div class="form-group">
                        <label for="username">Username</label>
                        <input type="text" id="username" formControlName="username" class="form-control">
                    </div>
                </div>

                <div class="row py-1">
                    <div class="form-group required">
                        <label for="registration" class="control-label">Registration Number</label>
                        <input [class.is-invalid] = "registration.invalid && registration.touched" [class.is-valid] = "registration.valid && registration.touched" 
                            type="text" id="registration" formControlName="registration" class="form-control" required>
                        <div *ngIf="registration.invalid && (registration.dirty || registration.touched)">
                            <small *ngIf="registration.errors?.['required']" class="text-danger">Registration is required.</small>
                        </div>
                    </div>
                </div>

                <div class="row py-1">
                    <div class="form-group required">
                        <label for="airframe_vendor" class="control-label">Airframe Manufacturer</label>
                        <input 
                            [class.is-invalid] = "airframe_vendor.invalid && airframe_vendor.touched" 
                            [class.is-valid] = "airframe_vendor.valid && airframe_vendor.touched" 
                            type="text" 
                            id="airframe_vendor" 
                            formControlName="airframe_vendor" 
                            class="form-control"
                            [ngbTypeahead]="searchAircraft" 
                            (focus)="aircraftFocus$.next($any($event).target.value)"
	                        (click)="aircraftClick$.next($any($event).target.value)"
                            #aircraftInput="ngbTypeahead"
                            [editable]="false"
                            required>
                        <div *ngIf="airframe_vendor.invalid && (airframe_vendor.dirty || airframe_vendor.touched)">
                            <small *ngIf="airframe_vendor.errors?.['required']" class="text-danger">Aircraft Manufacturer is required and must be chosen from the list. Choose Other if manufacturer is not found.</small>
                        </div>
                    </div>
                </div>


                <div class="row py-1">
                    <div class="form-group required">
                        <label for="airframe_model" class="control-label">Airframe Model</label>
                        <input [class.is-invalid] = "airframe_model.invalid && airframe_model.touched" [class.is-valid] = "airframe_model.valid && airframe_model.touched" 
                            type="text" id="airframe_model" formControlName="airframe_model" class="form-control" required>
                        <div *ngIf="airframe_model.invalid && (airframe_model.dirty || airframe_model.touched)">
                            <small *ngIf="airframe_model.errors?.['required']" class="text-danger">Airframe Model is required</small>
                        </div>
                    </div>
                </div>

                <div class="row py-1">
                    <div class="form-group">
                        <label for="airframe_serial">Airframe Serial Number</label>
                        <input type="text" id="airframe_serial" formControlName="airframe_serial" class="form-control">
                    </div>
                </div>
            


                <div class="row py-1">
                    <div class="form-group required">
                        <label for="engine_vendor" class="control-label">Engine Manufacturer</label>
                        <input 
                            [class.is-invalid] = "engine_vendor.invalid && engine_vendor.touched" 
                            [class.is-valid] = "engine_vendor.valid && engine_vendor.touched" 
                            type="text" 
                            id="engine_vendor" 
                            formControlName="engine_vendor" 
                            class="form-control"
                            [ngbTypeahead]="searchEngine" 
                            (focus)="engineFocus$.next($any($event).target.value)"
	                        (click)="engineClick$.next($any($event).target.value)"
                            #engineInput="ngbTypeahead"
                            [editable]="false">
                        
                        <div *ngIf="engine_vendor.invalid && (engine_vendor.dirty || engine_vendor.touched)">
                            <small *ngIf="engine_vendor.errors?.['required']" class="text-danger">Engine Manufacturer is required and must be chosen from the list. Choose Other if manufacturer is not found</small>
                        </div>
                    </div>
                </div>

                <div class="row py-1">
                    <div class="form-group required">
                        <label for="engine_model" class="control-label">Engine Model</label>
                        <input [class.is-invalid] = "engine_model.invalid && engine_model.touched" [class.is-valid] = "engine_model.valid && engine_model.touched" 
                            type="text" id="engine_model" formControlName="engine_model" class="form-control" required>
                        <div *ngIf="engine_model.invalid && (engine_model.dirty || engine_model.touched)">
                            <small *ngIf="engine_model.errors?.['required']" class="text-danger">Engine Model is required</small>
                        </div>
                    </div>
                </div>

                <div class="row py-1">
                    <div class="form-group">
                        <label for="engine_serial">Engine Serial Number</label>
                        <input type="text" id="engine_serial" formControlName="engine_serial" class="form-control">
                    </div>
                </div>

                <div *ngIf="serverError || serverSuccess" class="row py-1">
                    <div class="form-group">
                        <div class="alert" [class.alert-danger] = "serverError" [class.alert-success] = "serverSuccess" role="alert">
                            {{serverError}} {{serverSuccess}}
                        </div>
                    </div>
                </div>
            </div>

        
        </div>
        <div class="modal-footer">
            <button type="submit"  [disabled]="!aircraftForm.valid" class="btn btn-primary justify-content-md-center col-md-3">Submit</button>
            <button #closeModal type="button" class="btn btn-secondary"  (click)="modal.close('Close click')">Close</button>
        </div>
    </form>
</ng-template>