

<div class="container py-1">&nbsp;</div>

<div class="container">
    <h2>Pricing</h2>

    <div class="row py-1">
        <div class="col">
            <p>We offer a variety of plans to best meet your needs.  
                All plans come with a 30 day free trial and you can change or cancel at any time with a single clicks!
                After a simple sign-up process you'll be able to choose your plan, and get started right away.
            </p>
        </div>
    </div>



    <div class="row">
        <div class="col">
            <div [innerHTML]="trustedHtml"> </div>
        </div>
    </div>
    
</div>

