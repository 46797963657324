




<div class="container py-1">&nbsp;</div>

<div class="container">
<h2>Privacy Policy</h2>

<p>Last updated October 16, 2024</p>

<p>
    This Privacy Policy for LSRM AI LLC ("<span class="fw-bold">Company</span>," "<span class="fw-bold">we</span>," 
    "<span class="fw-bold">us</span>," or "<span class="fw-bold">our</span>"), describes how and why we might 
    collect, store, use, and/or share ("<span class="fw-bold">process</span>") your information when you use our 
    services ("<span class="fw-bold">Services</span>"), such as when you:
</p>

<ul class="list-group">
    <li class="list-group-item border-0">Visit our website at <a href="https://lsrm.ai/">https://lsrm.ai/</a>, or 
        any website of ours that links to this Privacy Policy</li>
    <li class="list-group-item border-0">Engage with us in other related ways ― including any sales, marketing, or events</li>
</ul>

<p>
    <span class="fw-bold">Questions or concerns?</span> Reading this Privacy Policy will help you understand your privacy 
    rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still 
    have any questions or concerns, please contact us at <a href="mailto:privacy@lsrm.ai">privacy&#64;lsrm.ai</a>.
</p>

<section>
<h5 class="mt-4">SUMMARY OF KEY POINTS</h5>
    
<p>
    <span class="fw-bold">This summary provides key points from our Privacy Policy, but you can find out more details about 
        any of these topics in the sections below.</span>
</p>

<p>
    <span class="fw-bold">What personal information do we process?</span> When you visit, use, or navigate our Services, 
    we may process personal information depending on how you interact with us and the Services, the choices you make, and 
    the products and features you use. For details see <span class="fst-italic fw-bold">personal information you disclose to us</span>.
</p>

<p>
    <span class="fw-bold">Do we process any sensitive personal information?</span> We do not process sensitive personal information.
</p>
 
<p>
    <span class="fw-bold">Do we receive any information from third parties?</span> We do not collect any information from third parties.
</p>
 
<p>
    <span class="fw-bold">How do we process your information?</span> We process your information to provide, improve, and administer 
    our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your 
    information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. 
    For details see <span class="fst-italic fw-bold">how we process your information</span>.
</p>
 
<p>
    <span class="fw-bold">In what situations and with which types of parties do we share personal information?</span> We may share 
    information in specific situations and with specific categories of third parties. For details see 
    <span class="fst-italic fw-bold">when and with whom we share your personal information</span>.
</p>
 
<p>
    <span class="fw-bold">How do we keep your information safe?</span> We have organizational and technical processes and procedures 
    in place to protect your personal information. However, no electronic transmission over the internet or information storage 
    technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other 
    unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your 
    information. For details see <span class="fst-italic fw-bold">how we keep your information safe</span>.
</p>
 
<p>
    <span class="fw-bold">What are your rights?</span> Depending on where you are located geographically, the applicable 
    privacy law may mean you have certain rights regarding your personal information. 
    For details see <span class="fst-italic fw-bold">your privacy rights</span>.
</p>
 
<p>
    <span class="fw-bold">How do I exercise my rights?</span> The easiest way to exercise your rights is contacting us or
    sending us a <span class="fst-italic fw-bold">data subject access request </span>. We will consider and act upon any 
    request in accordance with applicable data protection laws.
</p>
</section>

<section>
<h5 class="mt-4">WHAT INFORMATION DO WE COLLECT?</h5>
      
    <h6>Personal information you disclose to us</h6>

    <p>
        <span class="fw-bold">In Short:</span><span class="fst-italic"> We collect personal information that you provide to us.</span>
    </p>

    <p>
        We collect personal information that you voluntarily provide to us when you register on the Services, express 
        an interest in obtaining information about us or our products and Services, when you participate in activities 
        on the Services, or otherwise when you contact us.
    </p>

    <p>
        <span class="fw-bold">Personal Information Provided by You.</span> The personal information that we collect 
        depends on the context of your interactions with us and the Services, the choices you make, and the 
        products and features you use. The personal information we collect may include the following:
    </p>

    <ul class="list-group">
        <li class="list-group-item border-0">email addresses</li>
        <li class="list-group-item border-0">first and last names</li>
        <li class="list-group-item border-0">phone numbers</li>
        <li class="list-group-item border-0">usernames</li>
        <li class="list-group-item border-0">passwords</li>
    </ul>   

    <p>
        <span class="fw-bold">Sensitive Information.</span> We do not process sensitive Information.
    </p>

    <p>
        <span class="fw-bold">Payment Data.</span> We may collect data necessary to process your payment if you make
        purchases, such as your payment instrument number (e.g. credit card number), and the security code 
        associated with your payment instrument. All payment data is stored by Stripe. You may find their Privacy 
        Policy link(s) here: <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.
    </p>
    
    <p>
        All personal information that you provide to us must be true, complete, and accurate, and you must notify us 
        of any changes to such personal information.
    </p>


    <h6>Information automatically collected</h6>

    <p>
        <span class="fw-bold">In Short:</span><span class="fst-italic"> Some information — such as your Internet Protocol (IP) 
            address and/or browser and device characteristics — is collected automatically when you visit our Services.
        </span>
    </p>    

    <p>
        We automatically collect certain information when you visit, use, or navigate the Services. This information does
        not reveal your specific identity (like your name or contact information) but may include device and usage information,
        such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use our Services, and other technical information.
        This information is primarily needed to maintain the security and operation of our Services, and for our internal
        analytics and reporting purposes.
    </p>

    <p>
        Like many businesses, we also collect information through cookies and similar technologies.
    </p>

    <p>
        The information we collect includes:
    </p>
    <ul class="list-group">
        <li class="list-group-item border-0"><span class="fst-italic">Log and Usage Data.</span> Log and usage data is 
            service-related, diagnostic, usage, and performance information our servers automatically collect when 
            you access or use our Services and which we record in log files. Depending on how you interact with us, 
            this log data may include your IP address, device information, browser type, and settings and information 
            about your activity in the Services (such as the date/time stamps associated with your usage, pages and 
            files viewed, searches, and other actions you take such as which features you use), device event information 
            (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).    
        </li>
        <li class="list-group-item border-0"><span class="fst-italic">Device Data.</span> We collect device data such 
            as information about your computer, phone, tablet, or other device you use to access the Services. Depending 
            on the device used, this device data may include information such as your IP address (or proxy server), 
            device and application identification numbers, location, browser type, hardware model, Internet service 
            provider and/or mobile carrier, operating system, and system configuration information.
        </li>
        <li class="list-group-item border-0"><span class="fst-italic">Location Data.</span> We collect location data such 
            as information about your device's location, which can be either precise or imprecise. How much information 
            we collect depends on the type and settings of the device you use to access the Services. For example, we 
            may use GPS and other technologies to collect geolocation data that tells us your current location (based 
            on your IP address). You can opt out of allowing us to collect this information either by refusing access 
            to the information or by disabling your Location setting on your device. However, if you choose to opt out, 
            you may not be able to use certain aspects of the Services.
        </li>
    </ul>

</section>

<section>
<h5 class="mt-4">HOW DO WE PROCESS YOUR INFORMATION?</h5>

<p>
    <span class="fw-bold">In Short:</span><span class="fst-italic"> We process your information to provide, 
        improve, and administer our Services, communicate with you, for security and fraud prevention, 
        and to comply with law. We may also process your information for other purposes with your consent.
    </span>
</p>
    
<p>
    <span class="fw-bold">We process your personal information for a variety of reasons, depending on 
        how you interact with our Services, including:
    </span>
</p>

<ul class="list-group">
    <li class="list-group-item border-0"><span class="fw-bold">To facilitate account creation and 
        authentication and otherwise manage user accounts. </span>
        We may process your information so you can create and log in to your account, as well as keep your 
        account in working order.
    </li>
    <li class="list-group-item border-0"><span class="fw-bold">To deliver and facilitate delivery of 
        services to the user. </span>
        We may process your information to provide you with the requested service.
    </li>
    <li class="list-group-item border-0"><span class="fw-bold">To respond to user inquiries/offer support 
        to users. </span>
        We may process your information to respond to your inquiries and solve any potential issues you might 
        have with the requested service.
    </li>
    <li class="list-group-item border-0"><span class="fw-bold">To send administrative information to you. </span>
        We may process your information to send you details about our products and services, changes to our terms 
        and policies, and other similar information.
    </li>
    <li class="list-group-item border-0"><span class="fw-bold">To fulfill and manage your orders. </span>
        We may process your information to fulfill and manage your orders and exchanges made through the Services.
    </li>
    <li class="list-group-item border-0"><span class="fw-bold">To request feedback. </span>
        We may process your information when necessary to request feedback and to contact you about your use of our 
        Services.
    </li>
    <li class="list-group-item border-0"><span class="fw-bold">To protect our Services. </span>
        We may process your information as part of our efforts to keep our Services safe and secure, including fraud 
        monitoring and prevention.
    </li>
    <li class="list-group-item border-0"><span class="fw-bold">To evaluate and improve our Services, products,
        marketing, and your experience.</span>
        We may process your information when we believe it is necessary to identify usage trends, determine the
        effectiveness of our marketing campaigns, and to evaluate and improve our Services, products, marketing, and
        your experience.
    </li>
    <li class="list-group-item border-0"><span class="fw-bold">To identify usage trends. </span>
        We may process information about how you use our Services to better understand how they are being used so we 
        can improve them.
    </li>
</ul>
</section> 
    

<section>
<h5 class="mt-4">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h5>

<p>
    <span class="fw-bold">In Short:</span><span class="fst-italic"> We may share information in specific 
        situations described in this section and/or with the following categories of third parties.
    </span>
</p>

<p>
    <span class="fw-bold">Vendors, Consultants, and Other Third-Party Service Providers.</span> We may share 
    your data with third-party vendors, service providers, contractors, or agents (“third parties”) who 
    perform services for us or on our behalf and require access to such information to do that work. There are 
    agreements in place with our third parties, which are designed to help safeguard your personal information. 
    They commit to protect the data they hold on our behalf and to retain it for the period we instruct. 
</p>

<p>
    The categories of third parties we may share personal information with are as follows:
</p>

<ul class="list-group">
    <li class="list-group-item border-0">Performance Monitoring Tools</li>
    <li class="list-group-item border-0">Cloud Computing Services</li>
    <li class="list-group-item border-0">Data Analytics Services</li>
    <li class="list-group-item border-0">Payment Processors</li>
    <li class="list-group-item border-0">User Account Registration & Authentication Services</li>
    <li class="list-group-item border-0">AI Service Providers</li>
</ul>   

<p>
    We also may need to share your personal information in the following situations:
</p>

<ul class="list-group">
    <li class="list-group-item border-0"><span class="fw-bold">Business Transfers.</span> We may share or transfer your 
        information in connection with, or during negotiations of, any merger, sale of company assets, financing, or 
        acquisition of all or a portion of our business to another company.
    </li>
</ul>
</section>  

    <!-- When we use Google Analytics. We may share your information with Google Analytics to track and analyze the use of the 
         Services. The Google Analytics Advertising Features that we may use include: [List Features]. To opt out of being 
         tracked by Google Analytics across the Services, visit https://tools.google.com/dlpage/gaoptout. You can opt out of 
         Google Analytics Advertising Features through Ads Settings and Ad Settings for mobile apps. Other opt out means 
         include http://optout.networkadvertising.org/ and http://www.networkadvertising.org/mobile-choice. For more 
         information on the privacy practices of Google, please visit the Google Privacy & Terms page.
    When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). To find out more about Google's Privacy Policy, please refer to this link. We use certain Google Maps Platform APIs to retrieve certain information when you make location-specific requests. This includes: [List of Information Collected], and other similar information. A full list of what we use information for can be found in this section and in the previous section titled “HOW DO WE PROCESS YOUR INFORMATION?” [We obtain and store on your device (“cache”) your location for [#] months. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.] The Google Maps Platform APIs that we use store and access cookies and other information on your devices. If you are a user currently in the European Economic Area (EU countries, Iceland, Liechtenstein and Norway) or the United Kingdom, please take a look at our Cookie Notice, which can be found at this link: [Cookie Notice URL].
    Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
    Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions.
    Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.
    [Offer Wall. Our application(s) may display a third-party hosted “offer wall.” Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.] -->
 
    <!-- 5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
    
    In Short: We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.
    
    The Services, [including our offer wall], may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this Privacy Policy. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions. -->

<section>
<h5 class="mt-4">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h5>

<p>
    <span class="fw-bold">In Short:</span><span class="fst-italic"> We may use cookies and other tracking technologies to 
        collect and store your information.</span>
</p>
    
<p>    
    We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you 
    interact with the Services.  Some online tracking technologies help us maintain the security of our Services and 
    your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
</p>  

<h6>Google Analytics</h6>
<p>
    We may share your information with Google Analytics to track and analyze the use of the Services. To opt out of being
    tracked by Google Analytics across the Services, visit 
    <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>. For more information
    on the privacy practices of Google, please visit the <a href="https://policies.google.com/privacy">Google Privacy & Terms page</a>.
</p>
</section>

<section>
<h5 class="mt-4">DO WE OFFER ARTIFICIAL INTELLIGENCE BASED PRODUCTS?</h5>

<p>
    <span class="fw-bold">In Short:</span><span class="fst-italic"> We offer products, features, or tools powered
        by artificial intelligence (AI), machine learning (ML), or similar technologies.</span>
</p>

<p>
    As part of our Services we offer products, features, or tools that are powered by artificial intelligence,
    machine learning, or similar technologies (collectively, "AI Products"). These tools are designed to enhance
    your experience and provide you with innovative solutions.  The terms of this Privacy Policy govern your use
    of the AI Products within our Services.
</p>

<h6>Use of AI Technologies</h6>
<p>
    We provide the AI Products through third-party service providers ("AI Service Providers"), including OpenAI,
    Conhere, LlamaCloud and Amazon Web Services (AWS) AI. As outlines in this Privacy Policy, the content you upload
    as well as your questions and chat input and output will be shared with and processed by these AI Service Providers
    to enable your use of our AI Products for the purposes outlined in this Privacy Policy.  You must not use the AI
    Products in any way that violates the terms or policies of any AI Service Providers.
</p>

<h6>Our AI Products</h6>
<p>
    Our AI Products are designed for the following functions:
</p>

<ul class="list-group">
    <li class="list-group-item border-0">Natural Language Processing (NLP)</li>
    <li class="list-group-item border-0">Image Analysis</li>
    <li class="list-group-item border-0">AI Search</li>
    <li class="list-group-item border-0">Question & Answers</li>
    <li class="list-group-item border-0">Chatbots</li>
    <li class="list-group-item border-0">Speech Recognition</li>
</ul>

<h6>How We Process Your Data Using AI</h6>
<p> 
    All personal information processes using our AI Products is handled in line with our Privacy Policy and our agreements
    with third parties.  This ensures high security and safeguards your personal information throughout the process 
    giving you peace of mind about your data's safety.
</p>

</section>

<section>
<h5 class="mt-4">HOW LONG DO WE KEEP YOUR INFORMATION?</h5>

<p>
    <span class="fw-bold">In Short:</span><span class="fst-italic"> We keep your information for as long as necessary to 
        fulfill the purposes outlined in this Privacy Policy unless otherwise required by law.
    </span>
</p>

<p>
    We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Policy, 
    unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). 
    No purpose in this policy will require us keeping your personal information for longer than twelve (12) months past the 
    termination of the user's account.
</p>

<p>
    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize
    such information, or, if this is not possible (for example, because your personal information has been stored in backup
    archives), then we will securely store your personal information and isolate it from any further processing until deletion
    is possible.
</p>
</section>

<section>
<h5 class="mt-4">HOW DO WE KEEP YOUR INFORMATION SAFE?</h5>

<p>
    <span class="fw-bold">In Short:</span><span class="fst-italic"> We aim to protect your personal information through a 
        system of organizational and technical security measures.
    </span>
</p>

<p>
    We have implemented appropriate and reasonable technical and organizational security measures designed to protect the 
    security of any personal information we process. However, despite our safeguards and efforts to secure your information, 
    no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we 
    cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat 
    our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect 
    your personal information, transmission of personal information to and from our Services is at your own risk. You should 
    only access the Services within a secure environment.
</p>

</section>   
 

<section>
<h5 class="mt-4">DO WE COLLECT INFORMATION FROM MINORS?</h5>

<p>
    <span class="fw-bold">In Short:</span><span class="fst-italic"> We do not knowingly collect data from or market to 
        children under 18 years of age.
    </span>
</p>

<p>
    We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent
    that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's
    use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we
    will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become
    aware of any data we may have collected from children under age 18, please contact us at 
    <a href="mailto:privacy@lsrm.ai">privacy&#64;lsrm.ai</a>.
</p>

</section>

<section>
<h5 class="mt-4">WHAT ARE YOUR PRIVACY RIGHTS?</h5>

<p>
    <span class="fw-bold">In Short:</span><span class="fst-italic"> You may review, change, or terminate your account at any time,
        depending on your country, province, or state of residence.
    </span>
</p>

<p>
    <span class="fw-bold">Withdawing your consent:</span> If we are relying on your consent to process your personal information,
    which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at
    any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section
    "<span class="fst-italic fw-bold">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>" below.
</p>

<p>
    However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law
    allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than
    consent.
</p>


<h6>Account Information</h6>

<p>
    If ou would at any time like to review or change the information in your account or terminate your account, you can:
</p>

<ul class="list-group">
    <li class="list-group-item border-0">Log in to your account settings and update your user account.</li>
    <li class="list-group-item border-0">Contact us using the contact information provided.</li>
</ul>

<p>
    Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. 
    However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, 
    enforce our legal terms and/or comply with applicable legal requirements.
</p>

<p>
    <span class="fw-bold">Cookies and similar technologies:</span> Most Web browsers are set to accept cookies by default. If you 
    prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or 
    reject cookies, this could affect certain features or services of our Services. 
</p>

<p>
    If you have questions or comments about your privacy rights, you may email us at <a href="mailto:privacy@lsrm.ai">privacy&#64;lsrm.ai</a>.
</p>


</section>

<section>
<h5 class="mt-4">CONTROLS FOR DO-NOT-TRACK FEATURES</h5>

<p>
    Most web browsers and some mobile operating systems and mobile 
    applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to 
    have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for
    recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals
    or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online
    tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this
    Privacy Policy.
</p>
</section>

<section>
<h5 class="mt-4">DO WE MAKE UPDATES TO THIS NOTICE?</h5>

<p>
    <span class="fw-bold">In Short:</span><span class="fst-italic"> Yes, we will update this notice as necessary to stay compliant 
        with relevant laws.
    </span>
</p>

<p>
    We may update this Privacy Policy from time to time. The updated version will be indicated by an updated "Revised" date and the 
    updated version will be effective as soon as it is accessible. If we make material changes to this Privacy Policy, we may notify 
    you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review 
    this Privacy Policy frequently to be informed of how we are protecting your information.
</p>
</section>

<section>
<h5 class="mt-4">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h5>

<p>
    If you have questions or comments about this notice, you may email us at <a href="mailto:privacy@lsrm.ai">privacy&#64;lsrm.ai</a>.
</p>

</section>
  

<section>
<h5 class="mt-4">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h5>

<p>
    Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to 
    the personal information we collect from you, details about how we have procesed it, correct inaccuracies, or delete your
    personal information.  You may also have the right to withdraw your consent to our processing of your personal information.
    These rights may be limited in some circumstances by applicable law.  To request, review, update, or delete your personal
    information please submit a Data Subject Access Request (DSAR) by email to <a href="mailto:privacy@lsrm.ai">privacy&#64;lsrm.ai</a>.
</p>

</section>
</div>