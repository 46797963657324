<div class="container py-1">&nbsp;</div>

<div class="container">
    <h2>Terms of Service</h2>

    <p>Last updated October 16, 2024</p>


    <h4>AGREEMENT TO OUR LEGAL TERMS</h4>
 
<p>
    We are LSRM AI LLC ("<span class="fw-bold">Company</span>," "<span class="fw-bold">we</span>," "<span class="fw-bold">us</span>," or "<span class="fw-bold">our</span>")
</p>

<p>
    We operate the website <a href="https://lsrm.ai/">https://lsrm.ai/</a> (the "<span class="fw-bold">Site</span>"), as well as any other related products and 
    services that refer or link to these legal terms (the "<span class="fw-bold">Legal Terms</span>") (collectively, the "<span class="fw-bold">Services</span>").
</p>

<p>
    You can contact us by <a href="mailto:legal@lsrm.ai">email</a> at legal&#64;lsrm.ai
</p>

<p>
    These Legal Terms constitute a legally binding agreement made between you, whether personally or on 
    behalf of an entity ("you"), and LSRM AI LLC, concerning your access to and use of the Services. 
    You agree that by accessing the Services, you have read, understood, and agreed to be bound by all 
    of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY 
    PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
 
</p>


<p>
    Supplemental terms and conditions or documents that may be posted on the Services from time to time are 
    hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make 
    changes or modifications to these Legal Terms from time to time. We will alert you about any changes by 
    updating the "Last updated" date of these Legal Terms, and you waive any right to receive specific 
    notice of each such change. It is your responsibility to periodically review these Legal Terms to stay 
    informed of updates. You will be subject to, and will be deemed to have been made aware of and to have 
    accepted, the changes in any revised Legal Terms by your continued use of the Services after the date 
    such revised Legal Terms are posted.  
 </p>

<p> 
    The Services are intended for users who are at least 13 years old.  All users who are minors 
    in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, 
    and be directly supervised by, their parent or guardian to use the Services. If you are a minor, 
    you must have your parent or guardian read and agree to these Legal Terms prior to you using the Services.
</p>

<p>
    We recommend that you print a copy of these Legal Terms for your records.
</p>

<!--
<h4>TABLE OF CONTENTS</h4>

<ol class="list-group list-group-numbered">  
    <li class="list-group-item border-0 p-0">OUR SERVICES</li>
    <li class="list-group-item border-0 p-0">INTELLECTUAL PROPERTY RIGHTS</li>
    <li class="list-group-item border-0 p-0">USER REPRESENTATIONS</li>
    <li class="list-group-item border-0 p-0">USER REGISTRATION</li>
    <li class="list-group-item border-0 p-0">PRODUCTS</li>
    <li class="list-group-item border-0 p-0">PURCHASES AND PAYMENT</li>
    <li class="list-group-item border-0 p-0">SUBSCRIPTIONS</li>
    <li class="list-group-item border-0 p-0">RETURN/REFUNDS POLICY</li>
    <li class="list-group-item border-0 p-0">SOFTWARE</li>
    <li class="list-group-item border-0 p-0">PROHIBITED ACTIVITIES</li>
    <li class="list-group-item border-0 p-0">USER GENERATED CONTRIBUTIONS</li> 
    <li class="list-group-item border-0 p-0">CONTRIBUTION LICENSE</li>
    <li class="list-group-item border-0 p-0">GUIDELINES FOR REVIEWS</li>
    <li class="list-group-item border-0 p-0">MOBILE APPLICATION LICENSE</li>
    <li class="list-group-item border-0 p-0">SOCIAL MEDIA</li>
    <li class="list-group-item border-0 p-0">THIRD-PARTY WEBSITE AND CONTENT</li>
    <li class="list-group-item border-0 p-0">ADVERTISERS</li>
    <li class="list-group-item border-0 p-0">SERVICES MANAGEMENT</li>
    <li class="list-group-item border-0 p-0">PRIVACY POLICY</li>
    <li class="list-group-item border-0 p-0">DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</li>
    <li class="list-group-item border-0 p-0">TERM AND TERMINATION</li>
    <li class="list-group-item border-0 p-0">MODIFICATIONS AND INTERRUPTIONS</li>
    <li class="list-group-item border-0 p-0">GOVERNING LAW</li>
    <li class="list-group-item border-0 p-0">DISPUTE RESOLUTION</li>
    <li class="list-group-item border-0 p-0">CORRECTIONS</li>
    <li class="list-group-item border-0 p-0">DISCLAIMER</li>
    <li class="list-group-item border-0 p-0">LIMITATIONS OF LIABILITY</li>
    <li class="list-group-item border-0 p-0">INDEMNIFICATION</li>
    <li class="list-group-item border-0 p-0">USER DATA</li>
    <li class="list-group-item border-0 p-0">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
    <li class="list-group-item border-0 p-0">CALIFORNIA USERS AND RESIDENTS</li>
    <li class="list-group-item border-0 p-0">MISCELLANEOUS</li>
    <li class="list-group-item border-0 p-0">CONTACT US</li>
</ol>
-->
<section id="services">
<h5 class="mt-4">1. OUR SERVICES</h5>
<p>
    The information provided when using the Services is not intended for distribution to or use by any 
    person or entity in any jurisdiction or country where such distribution or use would be contrary 
    to law or regulation or which would subject us to any registration requirement within such 
    jurisdiction or country. Accordingly, those persons who choose to access the Services from other 
    locations do so on their own initiative and are solely responsible for compliance with local laws, 
    if and to the extent local laws are applicable. 
</p>

<p>
    The Services are not tailored to comply with industry-specific regulations (Health Insurance 
    Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), 
    etc.), so if your interactions would be subjected to such laws, you may not use the Services. 
    You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
</p>
</section>

<section>
<h5 class="mt-4">2. INTELLECTUAL PROPERTY RIGHTS</h5>
    
<h6>Our intellectual property</h6>
<p>   
    We are the owner or the licensee of all intellectual property rights in our Services, including all 
    source code, databases, functionality, software, website designs, audio, video, text, photographs, 
    and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, 
    and logos contained therein (the "Marks").
</p>

<p>    
    Our Content and Marks are protected by copyright and trademark laws (and various other intellectual 
    property rights and unfair competition laws) and treaties in the United States and around the world.
</p>

    
 <h6>Your use of our Services</h6>

 <p>
    You acknowledge that the Service does not replace or supercede any Federal Aviation Regulations (FARs).
    You must always comply with all applicable FARs and other regulations when performing maintenance or 
    operating an aircraft. This includes, but is not limited to 14 CFR 43 and with respect to Light Sport Repairmen, 
    14 CFR 65.107: "The repairman may not exercise the privileges of the certificate unless the repairman understands 
    the current instructions of the manufacturer and the maintenance manuals for the specific operation concerned."
    Always refer directly to these manuals and instructions from the manufacture's.
 </p>

 <p>
    We claim no intellectual property rights over the material you provide to the Service. You warrant that you 
    own or have the necessary rights to provide and use the information you provide to the Service (including any 
    information you provide to the Service through any third-party integrations) and that the information 
    does not infringe on the intellectual property rights of any third party. We do not redistrubte any of the 
    Content you provide to the Service, and only use it to provide the Service to you.
</p>
   
<h6>Copyright infringement</h6>

<p>
    We respect the intellectual property rights of others. If you believe that any material available on or through 
    the Services infringes upon any copyright you own or control, please immediately notify us using the contact information 
    provided below (a "Notification"). A copy of your Notification will be sent to the person who posted or stored the material
    addressed in the Notification. Please be advised that pursuant to federal law you may be held liable for damages if you make
    material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Services
    infringes your copyright, you should consider first contacting an attorney.
</p>
</section>
 
<section>
<h5 class="mt-4">3. USER REPRESENTATIONS</h5>

<p>
    By using the Services, you represent and warrant that: (1) all registration information you submit will be true, 
    accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such 
    registration information as necessary; (3) you have the legal capacity and you agree to comply with these Legal 
    Terms; (4) you are not under the age of 13; (5) you are not a minor in the jurisdiction in which you reside, or 
    if a minor, you have received parental permission to use the Services; (6) you will not access the Services 
    through automated or non-human means, whether through a bot, script, or otherwise; (7) you will not use the 
    Services for any illegal or unauthorized purpose; and (8) your use of the Services will not violate any applicable 
    law or regulation.
</p>

<p>
    If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to 
    suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof). 
</p>    
</section>    

<section>
<h5 class="mt-4">4. USER REGISTRATION</h5>

<p>
    You may be required to register to use the Services. You agree to keep your password confidential and will be 
    responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a 
    username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, 
    or otherwise objectionable.
</p>
</section>


<section>
<h5>5. PURCHASES AND PAYMENT</h5>
 
<p>
    We integrate with a third-party payment processor to facilitate purchases made on the Services. When you make a purchase,
    you will provide your payment information and any additional information required to complete your order directly to our
    third-party payment processor. You should be aware that online payment transactions are subject to validation checks by our
    payment processor and your card issuer and we are not responsible if your card issuer declines to authorize payment for any reason.
</p>

<p>
    You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. 
    You further agree to promptly update account and payment information, including email address, payment method, and payment 
    card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the 
    price of purchases as deemed required by us. We may change prices at any time. All payments shall be in US dollars.  
</p> 
 
<p>
    We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel 
    quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under 
    the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We 
    reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, 
    or distributors.
</p>
</section>

<section>
<h5 class="mt-4">6. SUBSCRIPTIONS</h5>
     
<h6>Billing and Renewal</h6>

<p>
    Your subscription will continue and automatically renew unless canceled. You consent to our charging your payment 
    method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you 
    cancel the applicable order. The length of your billing cycle will depend on the type of subscription plan you choose 
    when you subscribed to the Services.
</p>

<h6>Free Trial</h6>

<p>
    We offer a 30-day free trial to new users who register with the Services. The account will be charged according to 
    the user's chosen subscription at the end of the free trial.
</p>

<h6>Cancellation</h6>

<p>
    All purchases are non-refundable. You can cancel your subscription at any time by logging into your account. 
    Your cancellation will take effect at the end of the current paid term. If you have any questions or are 
    unsatisfied with our Services, please email us at info&#64;lsrm.ai 
</p>

<h6>Fee Changes</h6>

<p>
    We may, from time to time, make changes to the subscription fee and will communicate any price changes to you 
    in accordance with applicable law.
</p>
</section>

<section>
<h5 class="mt-4">7. PROHIBITED ACTIVITIES</h5>

<p>
    You may not access or use the Services for any purpose other than that for which we make the Services available. 
    The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed 
    or approved by us.
</p>

<p>
    As a user of the Services, you agree not to:
<p>
<ul class="list-group">
    <li class="list-group-item border-0">Upload any content related to a topic other than Light Sport or small aircraft 
        maintenance and operations.
    </li>
    <li class="list-group-item border-0">Systematically retrieve data or other content from the Services to create or compile, 
        directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
    <li class="list-group-item border-0">Make any unauthorized use of the Services, including collecting usernames 
        and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or 
        creating user accounts by automated means or under false pretenses.</li>
    <li class="list-group-item border-0">Use a buying agent or purchasing agent to make purchases on the Services.</li>
    <li class="list-group-item border-0">Circumvent, disable, or otherwise interfere with security-related features 
        of the Services, including features that prevent or restrict the use or copying of any Content or enforce 
        limitations on the use of the Services and/or the Content contained therein.</li>
    <li class="list-group-item border-0">Engage in unauthorized framing of or linking to the Services.</li>
    <li class="list-group-item border-0">Trick, defraud, or mislead us and other users, especially in any attempt to 
        learn sensitive account information such as user passwords.</li>
    <li class="list-group-item border-0">Make improper use of our support services or submit false reports of abuse or 
        misconduct.</li>
    <li class="list-group-item border-0">Engage in any automated use of the system, such as using scripts to send 
        comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
    <li class="list-group-item border-0">Interfere with, disrupt, or create an undue burden on the Services or the 
        networks or services connected to the Services.</li>
    <li class="list-group-item border-0">Attempt to impersonate another user or person or use the username of 
        another user.</li>
    <li class="list-group-item border-0">Use any information obtained from the Services in order to harass, abuse, 
        or harm another person.</li>
    <li class="list-group-item border-0">Use the Services as part of any effort to compete with us or otherwise 
        use the Services and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
    <li class="list-group-item border-0">Except as permitted by applicable law, decipher, decompile, disassemble, 
        or reverse engineer any of the software comprising or in any way making up a part of the Services.</li>
    <li class="list-group-item border-0">Attempt to bypass any measures of the Services designed to prevent or 
        restrict access to the Services, or any portion of the Services.</li>
    <li class="list-group-item border-0">Harass, annoy, intimidate, or threaten any of our employees or agents 
        engaged in providing any portion of the Services to you.</li>
    <li class="list-group-item border-0">Delete the copyright or other proprietary rights notice from any Content.</li>
    <li class="list-group-item border-0">Copy or adapt the Services' software, including but not limited to Flash, 
        PHP, HTML, JavaScript, or other code.</li>
    <li class="list-group-item border-0">Upload or transmit (or attempt to upload or to transmit) viruses, Trojan 
        horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive 
        text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, 
        disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.</li>
    <li class="list-group-item border-0">Upload or transmit (or attempt to upload or to transmit) any material that 
        acts as a passive or active information collection or transmission mechanism, including without limitation, 
        clear graphics interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes 
        referred to as "spyware" or "passive collection mechanisms" or "pcms").</li>
    <li class="list-group-item border-0">Except as may be the result of standard search engine or Internet browser 
        usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, 
        cheat utility, scraper, or offline reader that accesses the Services, or using or launching any unauthorized 
        script or other software.</li>
    <li class="list-group-item border-0">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
    <li class="list-group-item border-0">Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
</ul>
</section>

<section>
<h5 class="mt-4">8. THIRD-PARTY WEBSITES AND CONTENT</h5>

<p>
    The Services may contain (or you may be sent via the Site,) links to other websites ("Third-Party Websites") as well 
    as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, 
    and other content or items belonging to or originating from third parties ("Third-Party Content"). Such Third-Party 
    Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or 
    completeness by us, and we are not responsible for any Third- Party Websites accessed through the Services or any 
    Third-Party Content posted on, available through, or installed from the Services, including the content, accuracy, 
    offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party 
    Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party 
    Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the 
    Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, 
    and you should be aware these Legal Terms no longer govern. You should review the applicable terms and policies, 
    including privacy and data gathering practices, of any website to which you navigate from the Services or relating 
    to any applications you use or install from the Services. Any purchases you make through Third-Party Websites will 
    be through other websites and from other companies, and we take no responsibility whatsoever in relation to such 
    purchases which are exclusively between you and the applicable third party. You agree and acknowledge that we do not 
    endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm 
    caused by your purchase of such products or services. Additionally, you shall hold us blameless from any losses 
    sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any 
    contact with Third-Party Websites.
</p>
</section>

<section>
<h5 class="mt-4">9. SERVICES MANAGEMENT</h5>

<p>
    We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; 
    (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, 
    including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and 
    without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically 
    feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, 
    notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive 
    in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed 
    to protect our rights and property and to facilitate the proper functioning of the Services. 
</p>
</section>

<section>
<h5 class="mt-4">10. PRIVACY POLICY</h5>

<p>
    We care about data privacy and security. Please review our Privacy Policy: <a [routerLink]="['/privacy']">https://lsrm.ai/privacy</a>. 
    By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please 
    be advised the Services are hosted in the United States. If you access the Services from any other region of the world 
    with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable 
    laws in the United States, then through your continued use of the Services, you are transferring your data to the United
    States, and you expressly consent to have your data transferred to and processed in the United States. 
</p>

</section>

<section>
<h5 class="mt-4">11. TERM AND TERMINATION</h5>

<p>
    These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION 
    OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO 
    AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, 
    INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS 
    OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR 
    ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. 
 
    If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new 
    account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on 
    behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take 
    appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
</p>
</section>

<section>
<h5 class="mt-4">12. MODIFICATIONS AND INTERRUPTIONS</h5>

<p>
    We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our 
    sole discretion without notice. However, we have no obligation to update any information on our Services. 
    We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance 
    of the Services. 
 
    We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other 
    problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. 
    We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any 
    time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, 
    or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of 
    the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services 
    or to supply any corrections, updates, or releases in connection therewith.
</p>
</section>

<section>
<h5 class="mt-4">13. GOVERNING LAW</h5>

<p>
    These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of the 
    Commonwealth of Massachusetts applicable to agreements made and to be entirely performed within Commonwealth of 
    Massachusetts, without regard to its conflict of law principles.
</p>
</section>

<section>
<h5 class="mt-4">14. DISPUTE RESOLUTION</h5>


    <h6>Informal Negotiations</h6>
    <p>
        To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms 
        (each a "Dispute" and collectively, the "Disputes") brought by either you or us (individually, a "Party" and 
        collectively, the "Parties"), the Parties agree to first attempt to negotiate any Dispute (except those 
        Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. 
        Such informal negotiations commence upon written notice from one Party to the other Party.
    </p>

    <h6>Binding Arbitration</h6>
    <p>
        If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except those 
        Disputes expressly excluded below) will be finally and exclusively resolved through binding arbitration. 
        YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. 
        The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American 
        Arbitration Association ("AAA") and, where appropriate, the AAA's Supplementary Procedures for Consumer 
        Related Disputes ("AAA Consumer Rules"), both of which are available at the American Arbitration Association 
        (AAA) website. Your arbitration fees and your share of arbitrator compensation shall be governed by the 
        AAA Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. The arbitration may be 
        conducted in person, through the submission of documents, by phone, or online. The arbitrator will make a 
        decision in writing, but need not provide a statement of reasons unless requested by either Party. The 
        arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so. 
        Except where otherwise required by the applicable AAA rules or applicable law, the arbitration will take place 
        in Massachusetts. Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, 
        stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered 
        by the arbitrator.
    </p>

    <p>
        If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be commenced or 
        prosecuted in the state and federal courts located in Norfolk County, Massachusetts, and the Parties hereby 
        consent to, and waive all defenses of lack of personal jurisdiction, and forum non conveniens with respect 
        to venue and jurisdiction in such state and federal courts. Application of the United Nations Convention on 
        Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) 
        is excluded from these Legal Terms.
    </p>

    <p>
        In no event shall any Dispute brought by either Party related in any way to the Services be commenced more 
        than one (1) year after the cause of action arose. If this provision is found to be illegal or unenforceable, 
        then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found 
        to be illegal or unenforceable, and such Dispute shall be decided by a court of competent jurisdiction within 
        the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that 
        court.
    </p>

    <h6>Restrictions</h6>
    <p>
        The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To 
        the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is 
        no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action 
        procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative 
        capacity on behalf of the general public or any other persons.
    </p>

    <h6>Exceptions to Informal Negotiations and Arbitration</h6>
    <p>
        The Parties agree that the following Disputes are not subject to the above provisions concerning informal 
        negotiations and binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the 
        validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, 
        allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive 
        relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate 
        any Dispute falling within that portion of this provision found to be illegal or unenforceable and such 
        Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, 
        and the Parties agree to submit to the personal jurisdiction of that court.
    </p>
</section>

<section>
<h5 class="mt-4">15. CORRECTIONS</h5>

<p>
    There may be information on the Services that contains typographical errors, inaccuracies, or omissions, 
    including descriptions, pricing, availability, and various other information. We reserve the right to 
    correct any errors, inaccuracies, or omissions and to change or update the information on the Services 
    at any time, without prior notice.
</p>
</section>

<section>
<h5 class="mt-4">16. DISCLAIMER</h5>

<p>
    THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES 
    WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS 
    OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE 
    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE 
    MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES’ CONTENT OR 
    THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY 
    OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL 
    INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, 
    (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION 
    AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR 
    FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR 
    THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS 
    OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, 
    OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY 
    FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED 
    WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE 
    WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY 
    THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH 
    ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
</p>
</section>

<section>
<h5 class="mt-4">17. LIMITATIONS OF LIABILITY</h5>

<p>
    IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY 
    DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST 
    PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF 
    WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY 
    CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE 
    ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH 
    PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT 
    ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE 
    LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND 
    YOU MAY HAVE ADDITIONAL RIGHTS. 
</p>
</section>

<section>
<h5 class="mt-4">17. INDEMNIFICATION</h5>

<p>
    You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all 
    of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, 
    claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or 
    arising out of: (1) your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any 
    breach of your representations and warranties set forth in these Legal Terms; (5) your violation of the 
    rights of a third party, including but not limited to intellectual property rights; or (6) any overt 
    harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding 
    the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any 
    matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our 
    defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or 
    proceeding which is subject to this indemnification upon becoming aware of it.
</p>
</section>

<section>
<h5 class="mt-4">19. USER DATA</h5>

<p> 
    We will maintain certain data that you transmit to the Services for the purpose of managing the performance 
    of the Services, as well as data relating to your use of the Services. Although we perform regular routine 
    backups of data, you are solely responsible for all data that you transmit or that relates to any activity 
    you have undertaken using the Services. You agree that we shall have no liability to you for any loss or 
    corruption of any such data, and you hereby waive any right of action against us arising from any such 
    loss or corruption of such data.
</p>
</section>

<section>
<h5 class="mt-4">20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h5>

<p>
    Visiting the Services, sending us emails, and completing online forms constitute electronic communications. 
    You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, 
    and other communications we provide to you electronically, via email and on the Services, satisfy any legal 
    requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, 
    CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF 
    TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements 
    under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an 
    original signature or delivery or retention of non-electronic records, or to payments or the granting of 
    credits by any means other than electronic means. 
</p>
</section>

<section>
<h5 class="mt-4">21. CALIFORNIA USERS AND RESIDENTS</h5>

<p>
    If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of 
    the Division of Consumer Services of the California Department of Consumer Affairs in writing at 
    1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
</p>
</section> 

<section>
<h5 class="mt-4">22. MISCELLANEOUS</h5>

<p>
    These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services 
    constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any 
    right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal 
    Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations 
    to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act 
    caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms 
    is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable 
     these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no 
     joint venture, partnership, employment, or agency relationship created between you and us as a result of these 
     Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue 
     of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these 
     Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
</p>
</section>

<section>
<h5 class="mt-4">23. CONTACT US</h5>

<p>
In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, 
please contact us at: 
</p>

<p> 
    Email: <a href="mailto:info@lsrm.ai">info&#64;lsrm.ai</a>
</p>
</section>

</div>
