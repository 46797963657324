
<div class="container py-1">&nbsp;</div>

<div class="container">
    <h2>List of Documents</h2>


	<div *ngIf="!documents || documents.length == 0" class="row py-1">
        <div class="col">
            <div class="alert alert-secondary" role="alert">
                No documents found for {{ userService.user?.selected_aircraft?.registration }}.  You won't be able to ask any maintenance questions until 
				you add one or more documents.
            </div>
        </div>
    </div>

	<div class="row">
		<div class="col-11">
			<ul class="nav nav-tabs">
				<li class="nav-item">
				  <a class="nav-link" [ngClass]="active? 'active':''" (click)="showActive()">Active</a>
				</li>
				<li class="nav-item">
				  <a class="nav-link" [ngClass]="active? '':'active'"(click)="showArchived()">Archived</a>
				</li>
			</ul>
		</div>
		<div class="col-1">
			<button 
				placement="start" 
				ngbTooltip="Reload"
				class="btn btn-outline-primary bi bi-repeat " 
				[autoClose]="true"
				triggers="hover"
				(click)="reload()"></button>
		</div>
	</div>

    <div class="row">
        <div class="col">
			<table class="table table-striped">
				<thead>
					<tr>
						<th *ngIf="auth.admin" scope="col">ID</th>
						<th scope="col">Title</th>
						<th scope="col">Vendor</th>
						<th scope="col">Date</th>
						<th scope="col">Pages</th>
						<th scope="col">Status</th>
						<th scope="col">Actions</th>
					</tr>
				</thead>
				<tbody>
					@for (document of documents; track document.id) {
						<tr *ngIf="document.active === active">
							<th *ngIf="auth.admin" scope="row">{{ document.id | number }}</th>
							<td>{{ document.name }}</td>
							<td>{{ document.vendor }}</td>
							<td>{{ document.publish_date | date:'mediumDate' }}</td>
							<td>{{ document.pages | number }}</td>
							<td>{{ document.status }}</td>
							<td>
								<button 
									*ngIf="!document.active" 
									placement="start" 
									ngbTooltip="Re-Activate"
									type="button" 
									class="btn btn-primary bi bi-arrow-left-square-fill" 
									(click)="toggleActive(document)"></button>
								<button 
									*ngIf="document.active" 
									placement="start" 
									ngbTooltip="Archive"
									type="button" 
									class="btn btn-primary bi bi-archive-fill" 
									(click)="toggleActive(document)"></button>
						</tr>
						}
				</tbody>
			</table>
		</div>
	</div>

	<div class="row py-1">
		<div class="col-sm-10 col-md-8 col-xl-6">
			<button type="button" id="sendlogin" class="btn btn-primary" [routerLink]="['/add-document']">Add Document</button>
		</div>
	</div>


    <div class="row py-1">
        <div class="col">
            <p>Add any and all documents related to the maintenance of {{ userService.user?.selected_aircraft?.registration }}.  This includes
				the maintenance manual and any Safety Directives for the aircraft provided by {{ userService.user?.selected_aircraft?.airframe_vendor }} as well as the 
				maintenance manuals (Line, Heavy, etc.) for the engine provided by {{ userService.user?.selected_aircraft?.engine_vendor }}.  You should also add maintenance
				manuals for all installed avionics, and accessories such as the propeller, ELT, batteries (including backup batteries), and any other equipment
				that is installed on the aircraft.  It's best to add all relevant Service Bulletins, Service Letters, and Service Instructions and similar documents as well. If the
				aircraft manufacturer provides Kit Asembly Instructions, especialy if they are referenced in the maintenance manual, these should be added.  You may also
				choose to add general maintenance reference materials such as the FAA 43.13-1B.
				If you have any questions about what to add, please <a href="mailto:info@lsrm.ai">ask us</a>!
			</p>
        </div>
    </div>

</div>
