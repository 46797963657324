import { Injectable } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';

import { Amplify } from 'aws-amplify';
import { awsconfig } from '../../aws-amplify-config';

export const ADMIN: String = 'admins'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    // these state variables are used in components to determine what to display
    // but the 'real' state is via the isAuthenticated() and isAdmin() method
    public authenticated: boolean = false;
    public admin: boolean = false;

    constructor(public authenticator: AuthenticatorService) {
        Amplify.configure(awsconfig);
        this.isAuthenticated().then((isAuthenticated) => {
            this.authenticated = isAuthenticated;
        });
        this.isAdmin().then((isAdmin) => {
            this.admin = isAdmin;
        });
     }

    async isAuthenticated(): Promise<boolean> {
        try {
            const {username, userId, signInDetails} = await getCurrentUser()
            this.authenticated = true;
            return true
        } catch (error) {
            this.authenticated = false;
            return false;
        }
    }

    async getRoles(): Promise<string[]> {
        const authSession = await fetchAuthSession(); 
        const jwtRoles = authSession.tokens?.idToken?.payload['cognito:groups'] as string[]
        return jwtRoles;
    }
    
    async isAdmin(): Promise<boolean> {
        const jwtRoles = await this.getRoles();
        if (jwtRoles) {
            for (const role of jwtRoles) {
                if (role === ADMIN) {
                    this.admin = true;
                    return true;
                }
            }
        }
        this.admin = false;
        return false;
    }

    signOut() {
        this.authenticator.signOut();
        this.authenticated = false;
        this.admin = false;
    }

}
