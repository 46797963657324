

<div class="container py-1">&nbsp;</div>

<div class="container">
    <h2>Beta Signups</h2>
    <div class="row">
        <div class="col">
			<table class="table table-striped">
				<thead>
					<tr>
						<th scope="col">ID</th>
						<th scope="col">Name</th>
						<th scope="col">Email</th>
						<th scope="col">Notes</th>
						<th scope="col">Invited</th>
						<th scope="col">Accepted</th>
						<th scope="col">Signup Date</th>
					</tr>
				</thead>
				<tbody>
					@for (betaSignup of betaSignups; track betaSignup.id) {
						<tr>
							<th scope="row">{{ betaSignup.id | number }}</th>
							<td>{{ betaSignup.name }}</td>
							<td>{{ betaSignup.email }}</td>
                            <td>{{ betaSignup.notes }}</td>
                            <td>{{ betaSignup.invited }}</td>
                            <td>{{ betaSignup.accepted }}</td>
							<td>{{ betaSignup.created | date:'shortDate' }}</td>
						</tr>
						}
				</tbody>
			</table>
		</div>
	</div>

</div>
