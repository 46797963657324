import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    HeaderComponent,
    RouterModule, 
    FooterComponent
  ],
  template: `
      <app-header></app-header>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
  `,
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(public router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('Logging to GA, page_path: ', event.urlAfterRedirects);
        gtag('config', environment.gaMeasurementId, { 'page_path': event.urlAfterRedirects });
      }      
    })
  }    
}
