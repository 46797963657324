import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withCache } from '@ngneat/cashew';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { COGNITO_BEARER } from '../interceptors/authentication.interceptor';

@Injectable({
  providedIn: 'root'
})
export class PicklistService {

  constructor(private http: HttpClient) {}


  base_url = `${environment.apiUrl}/api/v1/lists/`;

  public getVendors(): Observable<string[]> {
    const url = this.base_url + `all-vendors`;
    return this.http.get<string[]>(url, { context: withCache().set(COGNITO_BEARER, true) });
  }

  public getAircraftVendors(): Observable<string[]> {
    const url = this.base_url + `aircraft-vendors`;
    return this.http.get<string[]>(url, { context: withCache().set(COGNITO_BEARER, true) });
  }

  public getEngineVendors(): Observable<string[]> {
    const url = this.base_url + `engine-vendors`;
    return this.http.get<string[]>(url, { context: withCache().set(COGNITO_BEARER, true) });
  }
}
