import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

// First, make sure user is authenticated (AuthService route is authenticated)
// Then, if it's further limited to specific roles, see if user has one of those roles
export const AuthGuard: CanActivateFn = async (route, state) => { 

  
  const router = inject(Router);
  const auth = inject(AuthService);
  const userService = inject(UserService);
  try {
    const isAuthenticated = await auth.isAuthenticated();
    if (!isAuthenticated) {
      console.log('User is not authenticated, redirecting to login page');
      router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
    
    // Make sure userService.user object is populated
    if (userService.user == undefined) {
      console.log('User is authenticated, but user state is not populated, populating now');
      await userService.populateUserState();
      // if still undefined, redirect to login page
      if (userService.user == undefined) {
        console.log('Couldnt populate user state, redirecting to login page');
        router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
      }
    }

    const roles: string[] = route.data['roles']
    if (roles) {
      // const authSession = await fetchAuthSession({ forceRefresh: false });
      const jwtRoles: string[] = await auth.getRoles();
      // some weirdness in the types of these string arrays
      if (jwtRoles) {
        for (const role of roles) {
          for (const jwtRole of jwtRoles) {
            if (role === jwtRole) {
              return true;
            }
          }
        }
      }
      // didn't successful satisfy role condition
      router.navigate(['/']);
      return false;
    }
    return true
  } catch (error) {
    // Redirect to the login page if an unexpected error occured
    console.error('Error in AuthGuard:', error);

    router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
};