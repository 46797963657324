import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';


// Protect pages requiring an aircraft to be selected.  Redirect user to
// Aircraft page if they do not have an aircraft selected.
// In the future we could use this guard to enforce subscription limits on number of aircraft
export const AircraftGuard: CanActivateFn = async (route, state) => { 

  
    const router = inject(Router);
    const userService = inject(UserService);
    try {
        // Make sure userService.user object is populated
        if (userService.user == undefined) {
            console.log('User is authenticated, but user state is not populated, populating now');
            await userService.populateUserState();
        }
    
        if (userService.user!.selected_aircraft) {
            return true;
        } else {
            console.log('User has not selected an aircraft, redirecting to aircraft page');
            router.navigate(['/aircraft']);
            return false;
        }
    } catch (error) {
        // Redirect to the aircraft if an unexpected error occured
        console.error('Error in AircraftGuard:', error);
    
        router.navigate(['/aircraft']);
        return false;
    }
}