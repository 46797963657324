
<div class="container py-1">&nbsp;</div>

<div class="container">
    <div class="row">
        <div class="col">
          <amplify-authenticator 
              [signUpAttributes]="['email']" 
              class="lsrm-amplify"
              [initialState]="initialState">
            <ng-template
              amplifySlot="authenticated"
              let-user="user"
              let-signOut="signOut"
            >
              {{ completeLogin() }}
            </ng-template>
          </amplify-authenticator>
        </div>   
      </div>
</div>
