import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [  ],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss',
})
export class PricingComponent {
  potioKey: string = environment.potioKey;
  trustedHtml: any;

  potioHtml: string = `
    <potio-pricing-table 
          action="signup" 
          key="` + this.potioKey + `">
    </potio-pricing-table>`

  constructor(private sanitizer: DomSanitizer) { 
    console.log("PricingComponent: constructor");
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(this.potioHtml);
  }

}



