<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ reference.document.name }} - Page {{ reference.page }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<img src="{{ reference.pageUrl }}" class="w-100 object-fit-contain">
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>

<ng-template #pdfviewer let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ reference.document.name }} - Page {{ reference.page }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<ng2-pdfjs-viewer [pdfSrc]="encodedPdfUrl" [page]="reference.page"></ng2-pdfjs-viewer>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>

<div class="card mb-3">
    <div class="row no-gutters">
      <div class="col-4">
        <a (click)="openFullPageModal(content)"><img src="{{ reference.thumbnailUrl }}" class="card-img img-thumbnail"></a>
      </div>
      <div class="col-8">
        <div class="card-body">
          <!-- <h5 class="card-title"><a (click)="openPdf()">{{ reference.document.name}}</a></h5> -->
          <h5 class="card-title"><a class="link-primary" (click)="openFullPageModal(pdfviewer)">{{ reference.document.name}}</a></h5>
          <!-- <ng2-pdfjs-viewer #externalPdfViewer [externalWindow]="true"></ng2-pdfjs-viewer> -->
          <h6 class="card-subtitle mb-2 text-muted">{{ reference.document.vendor }}</h6>
          <p class="card-text">Date: {{ reference.document.publish_date | date:'mediumDate' }}</p>
          <p class="card-text">Page: {{ reference.page }}</p>
        </div>
      </div>
    </div>
  </div>