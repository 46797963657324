import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ReferenceComponent } from '../../components/reference/reference.component';
import { Search } from '../../models/search.model';
import { SearchService } from '../../services/search.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [  
    ReactiveFormsModule,
    CommonModule,
    ReferenceComponent
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
  @ViewChild('question') questionElement!: ElementRef<HTMLInputElement>;

  search: Search;
  searching = false;
  showAnswer = false;

  searchForm =  this.formBuilder.group({
    question: ['', [Validators.required]],
  });

  constructor(private formBuilder: FormBuilder, private searchService: SearchService, public userService: UserService) { 
  }

  ngAfterViewInit(): void {
    this.questionElement.nativeElement.focus();
    console.log("Setting focus on: "+this.questionElement.nativeElement)
  }

  onSubmit(): void {
    if (this.searchForm.valid) {
      this.searching = true;
      this.showAnswer = true;
      this.search = this.searchForm.value;
      this.searchService.answerQuestion(this.userService.user!.selected_aircraft!, this.search).subscribe({
        next: (response) => {
          this.search = response;
          this.searching = false;
        },
        error: (error) => console.error(error),
      });
    }
  }

}
