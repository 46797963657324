
<div class="container mt-5">
    <div class="row py-2">
        <div class="col-lg-8 offset-lg-2">
            <form class="input-group" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                <span class="input-group-text" id="basic-addon1"><i class="bi bi-question" style="font-size: 2rem;"></i></span>
                <input type="text" id="question" formControlName="question" class="form-control" 
                       placeholder="Ask a maintenance question about {{ userService.user?.selected_aircraft?.registration }}..." #question>
            </form>
            <!--  -->
            
        </div>
    </div>

    <div *ngIf="showAnswer">
        <div class="row py-2">
            <div class="col-lg-8 offset-lg-2">
                <div class="input-group">
                    <span class="input-group-text"><i class="bi bi-airplane" style="font-size: 2rem;"></i></span>
                    <div id="answer" class="form-control align-items-center">
                        <div *ngIf="searching" class="spinner-border text-primary" role="status"></div>
                        <div *ngIf="search?.answer" [innerHtml]="search.answer"><div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row py-2">
            <div class="col-lg-8 offset-lg-2">
                <span class="disclaimer">LSRM.ai can make mistakes.  Always rely on information obtained directly from the manufacturer for the maintenance and operation of aircraft.</span>
            </div>
        </div>


        <div *ngIf="search?.references" class="row py-2">
            <h4>References</h4>
        </div>

        
        <component-reference *ngFor="let reference of search.references"
                             [reference]="reference"></component-reference>
    
    </div>


</div>



