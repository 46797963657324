import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';


// Protect pages requiring an active subscription.  Redirect user to 
// Subscription page if they do not have an active subscription.
// NB: This logic is based on the LSRM.ai copy of subscription state.  It's
// possible that they have an active subscription in Stripe somehow.
export const SubscriptionGuard: CanActivateFn = async (route, state) => { 

  
    const router = inject(Router);
    const userService = inject(UserService);
    try {
        // Make sure userService.user object is populated
        if (userService.user == undefined) {
            console.log('User is authenticated, but user state is not populated, populating now');
            await userService.populateUserState();
        }
    
        if (userService.user!.stripe_product) {
            return true;
        } else {
            console.log('User does not have an active subscription, redirecting to subscription page');
            router.navigate(['/subscription']);
            return false;
        }
    } catch (error) {
        // Redirect to the home page if an unexpected error occured
        console.error('Error in SubscriptionGuard:', error);
    
        router.navigate(['']);
        return false;
    }
}