

<div class="container py-1">&nbsp;</div>

<div class="container">
    <h2>Aircraft</h2>

    <div *ngIf="!aircraft || aircraft.length == 0" class="row py-1">
        <div class="col">
            <div class="alert alert-secondary" role="alert">
                No aircraft found.  Before you can add documents and ask questions you must add at least one aircraft.
            </div>
        </div>
    </div>

    <div *ngIf="aircraft && aircraft.length > 0 && !userService.user?.selected_aircraft" class="row py-1">
        <div class="col">
            <div class="alert alert-warning" role="alert">
                Before you can add documents and ask questions you must select an aircraft.
            </div>
        </div>
    </div>

    <div *ngIf="aircraft && activeAircraft().length > (userService.user?.stripe_product?.aircraft || 0)" class="row py-1">
        <div class="col">
            <div class="alert alert-danger" role="alert">
                You are over the maximum number of aircraft allowed by your subscription!  Please upgrade your subscription or de-activate enough aircraft to get below the limit.
            </div>
        </div>
    </div>

    <div class="row g-4">

        <div class="col-11">
			<ul class="nav nav-tabs">
				<li class="nav-item">
				  <a class="nav-link" [ngClass]="active? 'active':''" (click)="showActive()">Active</a>
				</li>
				<li class="nav-item">
				  <a class="nav-link" [ngClass]="active? '':'active'"(click)="showArchived()">Archived</a>
				</li>
			</ul>
		</div>
        
        
    @for (ac of aircraft; track ac.id) {

        
        <ng-container *ngIf="ac.active === active">
        <component-aircraft-edit #edit [aircraft]="ac"></component-aircraft-edit>


        <div class="col">
            <div class="card" style="width: 24rem;">
                <div class="card-body">
                    <h5 class="card-title">{{ ac.registration }}</h5>
                    <h6 class="card-subtitle mb-2 text-body-secondary" *ngIf="auth.admin">{{ ac.username }}</h6>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">{{ ac.airframe_vendor }} {{ ac.airframe_model }} s/n {{ ac.airframe_serial }}</li>
                        <li class="list-group-item">{{ ac.engine_vendor }}  {{ ac.engine_model }} s/n {{ ac.engine_serial }}</li>
                    </ul>
                    <div class="btn-toolbar">
                        <div class="btn-group me-2">
                            <button 
                                *ngIf="active"
                                class="btn btn-primary" 
                                [disabled]="userService.user?.selected_aircraft?.id == ac.id" 
                                (click)="selectAircraft(ac)">Select</button>
                        </div>
                        <div class="btn-group me-2"><button class="btn btn-primary" (click)="edit.openEditModal()">Edit</button></div>
                        <div class="btn-group me-2">
                            <button 
                                *ngIf="active"
                                class="btn btn-primary" 
                                (click)="toggleActive(ac)">De-Activate</button>
                        </div>
                        <div class="btn-group me-2">
                            <button 
                                *ngIf="!active"
                                class="btn btn-primary" 
                                [disabled]="!userService.user?.stripe_product?.aircraft || activeAircraft().length >= (userService.user?.stripe_product?.aircraft || 0)"
                                (click)="toggleActive(ac)">Activate</button>
                        </div>
                    </div>
                    <div class="btn-toolbar py-2" *ngIf="userService.user?.selected_aircraft?.id == ac.id">
                        <div class="btn-group me-2"><button class="btn btn-primary" routerLink="/docs">Documents</button></div>
                        <div class="btn-group me-2"><button class="btn btn-primary" routerLink="/search">Ask a ?</button></div>
                    </div>
                </div>
            </div>
        </div>
        </ng-container>
    }
    </div>

    <div class="row py-1">
        <div class="container py-1">&nbsp;</div>
        <component-aircraft-edit #add (refreshListEvent)="refreshList()"></component-aircraft-edit>
		<div class="col-sm-10 col-md-8 col-xl-6">
			<button 
                type="button" 
                id="addAircraft" 
                class="btn btn-primary"
                [disabled]="!userService.user?.stripe_product?.aircraft || activeAircraft().length >= (userService.user?.stripe_product?.aircraft || 0)"
                (click)="add.openEditModal()">Add Aircraft</button>
		</div>
	</div>
</div>
