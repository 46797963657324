


<div class="container py-1">&nbsp;</div>

<div class="container">
    <h2>Subscription</h2>

    <div *ngIf="!user?.stripe_product" class="row py-1">
        <div class="col">
            <p>Lets get started!  Choose a subscription that best meets your needs.  You can change it at any time and all plans come with a 30 day free trial!</p>
        </div>
    </div>

    <div *ngIf="user?.stripe_product" class="row py-1">
        <div class="col">
            <p>You can change your subscription here at any time.  All changes take effect immediately!</p>
        </div>
    </div>


    <div class="row">
        <div class="col">
            <div [innerHTML]="trustedHtml"> </div>
        </div>
    </div>
    
</div>

