import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { COGNITO_BEARER } from '../interceptors/authentication.interceptor';
import { BetaSignup } from '../models/beta-signup.model';

@Injectable({
  providedIn: 'root'
})
export class BetaSignupService {

  constructor(private http: HttpClient) { }

  url = `${environment.apiUrl}/api/v1/beta-signup/`;

  getAllBetaSignups(): Observable<BetaSignup[]> {
    return this.http.get<BetaSignup[]>(this.url, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }

  addBetaSignup(betaSignup: BetaSignup): Observable<BetaSignup> {
    return this.http.post<BetaSignup>(this.url, betaSignup);
  }
}
