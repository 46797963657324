import { Injectable } from '@angular/core';

import { HttpClient, HttpContext } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { COGNITO_BEARER } from '../interceptors/authentication.interceptor';
import { Aircraft } from '../models/aircraft.model';
import { User, UserUpdate, } from '../models/user.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

// This service is used to store user state as well as support the Admin User UI
export class UserService {
  // user state which includes selected aircraft and subscription info is stored here
  user?: User;

  constructor(private http: HttpClient, private authService: AuthService) {
    console.log("UserService constructor");
    // this.authService.isAuthenticated().then((isAuthenticated) => {
    //   if (isAuthenticated) {
    //     this.populateUserState();
    //   }
    // });
  }
  
  url = `${environment.apiUrl}/api/v1/user/`;

  /**
   * Admin only function to get all users.
   *
   * @returns An Observable that emits an array of User objects.
   */
  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.url + 'all', { context: new HttpContext().set(COGNITO_BEARER, true) });
  }

  getUser(): Observable<User> {
    return this.http.get<User>(this.url, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }

  updateUser(user: User): Observable<User> {
    const updateUser = user as UserUpdate;
    updateUser.selected_aircraft_id = user.selected_aircraft? user.selected_aircraft.id : null;
    return this.http.put<User>(this.url + user.id, updateUser, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }

  async populateUserState(): Promise<User>  {
    console.log("UserService: populateUserState");
    this.user = await firstValueFrom(this.getUser())
    // console.log("UserService: populateUserState: user", this.user);
    return this.user;
  }
  
  selectAircraft(aircraft: Aircraft | null) {
    if (!this.user) {
      throw new Error("User not defined");
    }
    this.user.selected_aircraft = aircraft;
    this.updateUser(this.user).subscribe({
      next: (response) => this.user = response,
      error: (error) => console.error(error),
    });
  }

  signOut() {
    this.user = undefined;
  }

}
