import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { COGNITO_BEARER } from '../interceptors/authentication.interceptor';
import { Redirect } from '../models/stripe.model';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private http: HttpClient) {}

  base_url = `${environment.apiUrl}/api/v1/stripe/`;

  public gotoBillingPortal(): Observable<Redirect> {
    const url = this.base_url + `billing`;
    return this.http.get<Redirect>(url, { context: new HttpContext().set(COGNITO_BEARER, true) });
  }
}
