<header>
    <nav ngbNav #nav="ngbNav" class="navbar navbar-expand-md navbar-light fixed-top bg-light">
      <div class="container">
          <ng-container *ngIf="auth.authenticated">
            <a class="navbar-brand" [routerLink]="['/search']"><img src="assets/LSRM.ai.png" alt="LSRM.ai"/></a>
            <span class="badge rounded-pill bg-secondary text-light">{{ userService.user?.selected_aircraft?.registration }}</span>
          </ng-container>
          <ng-container *ngIf="!auth.authenticated">
            <a class="navbar-brand" [routerLink]="['']"><img src="assets/LSRM.ai.png" alt="LSRM.ai"/></a>
          </ng-container>
          <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button> -->
          <button class="navbar-toggler navbar-toggler-right" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

                  <ng-container *ngIf="auth.authenticated">

                    <li class="nav-item"><a class="nav-link" (click)="isNavbarCollapsed = true" [routerLink]="['/search']">Ask a ?</a></li>

                    <li ngbDropdown class="nav-item" role="presentation">
                      <button type="button" class="nav-link" ngbDropdownToggle>Tools</button>
                      <div ngbDropdownMenu>
                        <button ngbDropdownItem (click)="isNavbarCollapsed = true" [routerLink]="['/docs']">Documents</button>
                        <button ngbDropdownItem (click)="isNavbarCollapsed = true" [routerLink]="['/aircraft']">Aircraft</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)="isNavbarCollapsed = true" [routerLink]="['/billing']">Billing</button>
                        <button ngbDropdownItem (click)="isNavbarCollapsed = true" [routerLink]="['/subscription']">Subscription</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)="isNavbarCollapsed = true" [routerLink]="['/terms']">Terms of Service</button>
                        <button ngbDropdownItem (click)="isNavbarCollapsed = true" [routerLink]="['/privacy']">Data Privacy</button>
                        <button ngbDropdownItem (click)="isNavbarCollapsed = true"  href="mailto:info@lsrm.ai">Contact Us</button>

                        
                      </div>
                    </li>
                    <ng-container *ngIf="auth.admin">
                      <li ngbDropdown class="nav-item" role="presentation">
                        <button type="button" class="nav-link" ngbDropdownToggle>Admin</button>
                        <div ngbDropdownMenu>
                          <button ngbDropdownItem (click)="isNavbarCollapsed = true" [routerLink]="['/user-list']">Users</button>
                          <button ngbDropdownItem (click)="isNavbarCollapsed = true" [routerLink]="['/beta-signup-list']">Beta Signups</button>
                          <div class="dropdown-divider"></div>
                          <button ngbDropdownItem>Yep, another</button>
                        </div>
                      </li>
                    </ng-container>
                    <li class="nav-item">
                      <button type="button" id="sendlogout" class="btn btn-primary" (click)="isNavbarCollapsed = true; signOut()">Sign Out</button>
                    </li>
                  </ng-container>

                  <ng-container *ngIf="!auth.authenticated">
                    <li class="nav-item"><a class="nav-link" (click)="isNavbarCollapsed = true" href="mailto:info@lsrm.ai">Contact</a></li>
                    <li class="nav-item"><a class="nav-link" (click)="isNavbarCollapsed = true" [routerLink]="['/pricing']">Pricing</a></li>
                    <li class="nav-item">
                      <button type="button" id="sendlogin" class="btn btn-primary" (click)="isNavbarCollapsed = true" [routerLink]="['/login']" [queryParams]="{initialState: 'signIn'}">Login</button>
                    </li>
                  </ng-container>
                  
              </ul>
          </div>
      </div>
    </nav>
</header>
<div class="container py-3">&nbsp;</div>