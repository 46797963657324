import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Document } from '../../models/document.model';
import { AuthService } from '../../services/auth.service';
import { DocumentService } from '../../services/document.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-docs-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgbTooltipModule,
  ],
  templateUrl: './docs-list.component.html',
  styleUrl: './docs-list.component.scss',
})
export class DocsListComponent {
  documents: Document[] = [];
  active: boolean = true;

  constructor(public auth: AuthService, private documentService: DocumentService,  public userService: UserService) {
  } 

  ngOnInit() {
    this.reload()

  }

  reload() {
    this.documentService.getAircraftDocuments(this.userService.user!.selected_aircraft!).subscribe({
      next: (response) => this.documents = response,
      error: (error) => console.error(error),
    });
  }
  showActive() {
    this.active = true;
  }

  showArchived() {
    this.active = false;
  }

  toggleActive(document: Document) {
    document.active = !document.active;
    this.documentService.updateAircraftDocument(document, this.userService.user!.selected_aircraft!).subscribe({
        next: (response) => console.log(response),
        error: (error) => console.error(error),
      });
    }

}
