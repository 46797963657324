

<div class="container py-1">&nbsp;</div>

<div class="container-md">
    <h2 class="">Add Document - {{ userService.user?.selected_aircraft?.registration }}</h2>

<form [formGroup]="addDocumentForm" (ngSubmit)="onSubmit()">
    <div class="row py-1">
        <div class="input-group">
            <span class="input-group-text">Manufacturer</span>
            <input 
                type="text" 
                id="vendor" 
                formControlName="vendor" 
                class="form-control" 
                [ngbTypeahead]="search"
	            (focus)="focus$.next($any($event).target.value)"
	            (click)="click$.next($any($event).target.value)"
	            #instance="ngbTypeahead"
                [editable]="false">
            <div *ngIf="vendor.invalid && (vendor.dirty || vendor.touched)">
                <small *ngIf="vendor.errors?.['required']" class="text-danger">Manufacturer is required and must be chosen from the list. Choose Other if manufacturer is not found.</small>
            </div>
        </div>
    </div>



    <div class="row py-1">
        <div class="input-group">
            <input type="file" class="form-control file-input"  (change)="onFileSelected($event)" #fileUpload>
            <span class="input-group-text">File to Upload</span>
            <input class="form-control" type="text" formControlName="file" placeholder = "No file selected" [value]="file?.name" readonly>
            <button color="primary" class="btn btn-outline-secondary upload-btn bi bi-cloud-upload"
                (click)="fileUpload.click()">
            </button> 
        </div>
    </div>

    <div class="row py-1 row-cols-sm-auto">
        <div class="col-12">
            <div class="input-group">
                <span class="input-group-text">Date Published</span>
                <input 
                    class="form-control" 
                    type="text" 
                    id="publish_date" 
                    formControlName="publish_date" 
                    ngbDatepicker 
                    #d="ngbDatepicker"
                    placeholder="yyyy-mm-dd" 
                    />
                <button class="btn btn-outline-secondary bi bi-calendar3"  (click)="d.toggle()" type="button" #buttonEl></button>
            </div>
        </div>
    </div>
    

    <div class="row py-1">
        <div class="form-group">
            <button type="submit"  [disabled]="!addDocumentForm.valid || !file" class="btn btn-primary">Submit</button>
            <button type="button" class="btn btn-outline-secondary mx-2" [routerLink]="['/docs']">Cancel</button>  
        </div>
    </div>

    <div *ngIf="serverError || serverSuccess" class="row py-1">
        <div class="form-group">
            <div class="alert" [class.alert-danger] = "serverError" [class.alert-success] = "serverSuccess" role="alert">
                {{serverError}} {{serverSuccess}}
            </div>
        </div>
    </div>

</form>