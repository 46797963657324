import { DatePipe } from '@angular/common';
import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfJsViewerComponent, PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { Reference } from '../../models/reference.model';

@Component({
  selector: 'component-reference',
  standalone: true,
  imports: [ 
    PdfJsViewerModule,
    DatePipe,
  ],
  templateUrl: './reference.component.html',
  styleUrl: './reference.component.scss'
})
export class ReferenceComponent {
  @Input() reference!: Reference;
  @ViewChild('externalPdfViewer') public externalPdfViewer: PdfJsViewerComponent;

  get encodedPdfUrl() {
    return encodeURIComponent(this.reference.documentUrl);
  }

  // used if we want to open the pdf in a new tab
  // public openPdf() {
  //   console.log("opening pdf in new tab!");
  //   this.externalPdfViewer.pdfSrc = encodeURIComponent(this.reference.documentUrl);
  //   this.externalPdfViewer.page = this.reference.page;
  //   this.externalPdfViewer.refresh();
  // }

  private modalService = inject(NgbModal);

  openFullPageModal(content: TemplateRef<any>) {
		this.modalService.open(content, { fullscreen: true });
	}
}
