import { Routes } from '@angular/router';
import { AddDocumentComponent } from './pages/add-document/add-document.component';
import { AircraftListComponent } from './pages/aircraft-list/aircraft-list.component';
import { BetaSignupListComponent } from './pages/beta-signup-list/beta-signup-list.component';
import { BetaSignupComponent } from './pages/beta-signup/beta-signup.component';
import { BillingComponent } from './pages/billing/billing.component';
import { DocsListComponent } from './pages/docs-list/docs-list.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SearchComponent } from './pages/search/search.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { TermsComponent } from './pages/terms/terms.component';
import { UserListComponent } from './pages/user-list/user-list.component';

import { AircraftGuard } from './guards/aircraft.guard';
import { AuthGuard } from './guards/auth.guard';
import { SubscriptionGuard } from './guards/subscription.guard';
import { ADMIN } from './services/auth.service';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: 'LSRM.ai - Home'
  },
  {
    path: 'terms',
    component: TermsComponent,
    title: 'LSRM.ai - Terms of Service'
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    title: 'LSRM.ai - Data Privacy Policy'
  },
  {
      path: 'docs',
      component: DocsListComponent,
      title: 'LSRM.ai - List of Source Documents',
      canActivate: [AuthGuard, SubscriptionGuard, AircraftGuard]
    },
    {
      path: 'add-document',
      component: AddDocumentComponent,
      title: 'LSRM.ai - Add a Document',
      canActivate: [AuthGuard, SubscriptionGuard, AircraftGuard]
    },
    {
      path: "login",
      component: LoginComponent,
      title: 'LSRM.ai - Login'
    },
    {
      path: "beta-signup",
      component: BetaSignupComponent,
      title: 'LSRM.ai - Sign Up for Beta Access'
    },
    {
      path: "beta-signup-list",
      component: BetaSignupListComponent,
      title: 'LSRM.ai - Beta Signups',
      canActivate: [AuthGuard], 
      data: { roles: [ADMIN] }
    },
    {
      path: "search",
      component: SearchComponent,
      title: 'LSRM.ai - Ask a Question',
      canActivate: [AuthGuard, SubscriptionGuard, AircraftGuard]
    },
    {
      path: "aircraft",
      component: AircraftListComponent,
      title: 'LSRM.ai - Aircraft',
      canActivate: [AuthGuard, SubscriptionGuard]
    },
    {
      path: "pricing",
      component: PricingComponent,
      title: 'LSRM.ai - Pricing'
    },
    {
      path: "subscription",
      component: SubscriptionComponent,
      title: 'LSRM.ai - Subscription',
      canActivate: [AuthGuard]
    },
    {
      path: "billing",
      component: BillingComponent,
      title: 'LSRM.ai - Billing',
      canActivate: [AuthGuard]
    },
    {
      path: "user-list",
      component: UserListComponent,
      title: 'LSRM.ai - Users',
      canActivate: [AuthGuard], 
      data: { roles: [ADMIN] }
    },
    { path: '**', redirectTo: '' }
];
